import React, { useState, useEffect } from "react";

export default function Home() {
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  return (
    <>
      <div
        style={{
          borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
          padding: "25px",
        }}
      >
        <img src="/img/logo.svg" alt={`Fehra ${width}`} />
      </div>
      <div
        style={{
          position: "relative",
          textAlign: "center",
          borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
        }}
      >
        <video
          style={{ width: "100%", maxWidth: "700px" }}
          controls={false}
          autoPlay
          muted
          playsInline
        >
          <source src="/media/fehra.mp4" type="video/mp4" />
        </video>
      </div>
      <div
        style={{
          position: "relative",
          textAlign: "center",
          borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
        }}
      >
        <img
          src="/img/text.svg"
          style={{ maxWidth: 350, padding: 25 }}
          alt="Fehra"
        />
        <p
          style={{
            textAlign: "left",
            margin: "0 auto",
            maxWidth: 350,
            fontSize: 14,
            lineHeight: "100%",
            color: "#887E78",
          }}
        >
          New site ~~~coming soon~~~
        </p>
        <br />
      </div>
      <div style={{ float: "right", width: "50%" }}>
        <div
          style={{
            float: "left",
            padding: 24,
            width: 150,
            borderLeft: "1px solid rgba(0, 0, 0, 0.1)",
          }}
        >
          <span style={{ color: "#887E78" }}>
            Segue a gente
            <a
              style={{
                color: "#232323",
                fontSize: 20,
                marginTop: width < 768 ? 15 : 5,
                display: "block",
                textDecoration: "none",
              }}
              href="https://instagram.com/fehra______"
              target={"_blank"}
              rel="noreferrer"
            >
              @fehra______
            </a>
          </span>
        </div>
      </div>
      <div style={{ float: "left", width: "50%" }}>
        <div
          style={{
            float: "right",
            padding: 24,
            width: 150,
          }}
        >
          <span style={{ color: "#887E78" }}>
            Manda um
            <a
              style={{
                color: "#232323",
                fontSize: 20,
                marginTop: width < 768 ? 15 : 5,
                display: "block",
              }}
              href="mailto:alo@fehra.co"
              rel="noreferrer"
            >
              alo@fehra.co
            </a>
          </span>
        </div>
      </div>
    </>
  );
}
